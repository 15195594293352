import React, { useRef, useState } from "react";
import {
  notification,
  Spin,
  Card,
  Popconfirm,
  Icon,
  Tag,
  Empty,
  Tooltip,
  Button,
  Popover,
  Badge,
} from "antd";
import moment from "moment";
import api from "../../../../../services/api";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../../../pages/Users/locales";
import { formatters } from "../../../../../utils/formatters";
import { Show } from "../../../../../contexts/PermissionContext";
import InfoRow from "../../../../../components/InfoRow";
import { useOnMount } from "../../../../../hooks";
import { getBenefitDescription } from "../../../../Coupons/utils";
import { CSVLink } from "react-csv";
import {
  PowerPlugIcon,
  TicketIcon,
  // ZapIcon,
} from "../../../../../assets/icons";
import { BRANDS } from "./Details";
import { getAppByAlias } from "../../../../../constants/apps";
import {
  formatExcel,
  getHeaders,
} from "../../../../ChargesHistory/ListChargesHistory";
import { Copy } from "../../../../../components/Copy";
import { IdleStartRow } from "./components/IdleStartRow";

const SATISMETER_PROJECTS = {
  development: {
    projectId: "62f6418d9cc03568f60a3e0b",
    name: "[dev] App Tupi",
    surveys: {
      "first-charge": {
        surveyId: "62fbdd943523787e9b5cc8a0",
        name: "[dev] NPS 1ª Carga",
        metric: "nps",
      },
      "new-transactional": {
        surveyId: "6377dc4b764986ebee62c181",
        metric: "nps",
        name: "[dev] NPS Transacional",
      },
      transactional: {
        surveyId: "62fbde453523787e9b5cc8a1",
        metric: "csat",
        name: "[dev] [legado] CSAT Transacional",
      },
      recurrent: {
        surveyId: "62fbde533523787e9b5cc8a2",
        metric: "nps",
        name: "[dev] [legado] NPS Recorrente (90 dias)",
      },
    },
  },
  production: {
    projectId: "627089b68cfdd2cfbbd83e00",
    name: "App Tupi",
    surveys: {
      "first-charge": {
        surveyId: "627c8a989109c0186cf797a8",
        metric: "nps",
        name: "NPS 1ª Carga",
      },
      "new-transactional": {
        surveyId: "6377dc4a764986ebee62c180",
        metric: "nps",
        name: "NPS Transacional",
      },
      transactional: {
        surveyId: "62f640789cc03568f60a3e07",
        metric: "csat",
        name: "[legado] CSAT Transacional",
      },
      recurrent: {
        surveyId: "62f640a29cc03568f60a3e08",
        metric: "nps",
        name: "[legado] NPS Recorrente (90 dias)",
      },
    },
  },
};

const satismeterProject =
  SATISMETER_PROJECTS[
    process.env.REACT_APP_ENV_NAME === "produção" ? "production" : "development"
  ];

// TODO: translate properly; multiple times in this file
// TODO: proper texts in locales file
const satismeterSurveyMetrics = {
  nps: {
    text: {
      pt: "NPS",
    },
    getResult: (rating) => {
      if (rating >= 9) {
        return "promoter";
      }
      if (rating >= 7) {
        return "neutral";
      }
      return "detractor";
    },
  },
  csat: {
    text: {
      pt: "CSAT",
    },
    getResult: (rating) => {
      if (rating >= 4) {
        return "satisfied";
      }
      if (rating >= 3) {
        return "neutral";
      }
      return "unsatisfied";
    },
  },
};

const satismeterResults = {
  promoter: {
    color: "#43c15f",
    text: { pt: "Promotor" },
  },
  passive: {
    color: "#fd9537",
    text: { pt: "Passivo" },
  },
  detractor: {
    color: "#e12d2d",
    text: { pt: "Detrator" },
  },
  satisfied: {
    color: "#43c15f",
    text: { pt: "Satisfeito" },
  },
  neutral: {
    color: "#fd9537",
    text: { pt: "Neutro" },
  },
  unsatisfied: {
    color: "#e12d2d",
    text: { pt: "Insatisfeito" },
  },
};

const BenefitDescription = ({ benefit, currency, freire }) => {
  // const { iconColor, tagColor, text } = getBenefitDescription(benefit, currency, freire);
  const { text } = getBenefitDescription(benefit, currency, freire);

  return (
    // <Tag color={tagColor} className="paymentStatusTag">
    <Tag color="purple" className="paymentStatusTag">
      <Icon
        // component={() => <TicketIcon color={iconColor ?? tagColor} />}
        component={() => <TicketIcon color="#722ed1" />}
        style={{
          marginRight: 3,
        }}
      />
      {text}
    </Tag>
  );
};

// TODO: show payment charge information
// TODO: only if charge contains the payment charge information
// const PaymentChargeDescription = ({ charge, freire }) => (
//   // <Tag color="orange" className="paymentStatusTag" style={{ paddingLeft: 6 }}>
//   <Tag className="paymentStatusTag" style={{ paddingLeft: 6 }}>
//     <Icon
//       // component={() => <ZapIcon color="#eb7c05" />}
//       component={() => <ZapIcon />}
//       style={{
//         marginRight: 2,
//       }}
//     />
//     R$ 1,00 / kWh (teste)
//     {/* teste */}
//   </Tag>
// );

// TODO: refinar permissões

// TODO: nfse

// TODO: finalizar carga

// TODO: total carregado em kWh
// TODO: país, moeda
// TODO: total gasto em estações tupinambá
// TODO: total economizado com cupons

// TODO: estação favorita
// TODO: tempo médio entre as cargas
// TODO: tempo desde a última carga
// TODO: tempo para a primeira carga

// TODO: ícone em cada campo da tabela
// TODO: exibir preço do kWh

// TODO: kWh carregado e árvores
// TODO: grupo de permissão

// TODO: estação ou empresa ou empresa mãe deletada

const DiscountValue = ({ charge, freire }) => {
  const discountValueFormatted = (
    <span style={{ textDecoration: "line-through" }}>
      {formatters.currency(
        freire.userLanguage,
        charge.currency
      )((charge.chargeValue + charge.discountValue) / 100)}
    </span>
  );

  return charge.chargeValue > 0 ? (
    <Popover
      content={
        <div>
          <div>
            <div>
              <strong>{freire(texts.DISCOUNT)}:</strong>{" "}
              {formatters.currency(
                freire.userLanguage,
                charge.currency
              )(charge.discountValue / 100)}
            </div>
          </div>
        </div>
      }
    >
      {discountValueFormatted}
    </Popover>
  ) : (
    discountValueFormatted
  );
};

const PaymentStatus = ({ chargeValue, freire, paymentSuccess, posPayment }) => {
  if (!paymentSuccess) {
    return (
      <Tag color="red" className="paymentStatusTag" style={{ paddingLeft: 2 }}>
        <Icon type="close" style={{ color: "red", marginRight: 3 }} />
        {freire(texts.NOT_PAID)}
      </Tag>
    );
  }

  if (posPayment) {
    return (
      <Tag color="blue" className="paymentStatusTag" style={{ paddingLeft: 3 }}>
        <Icon
          type="info-circle"
          style={{ color: "#1890ff", marginLeft: 3, marginRight: 3 }}
        />
        {freire(texts.POS_PAYMENT)}
      </Tag>
    );
  }

  const paid = chargeValue >= 100;

  return (
    <Tag
      color="green"
      className="paymentStatusTag"
      {...(paid && { style: { paddingLeft: 5 } })}
    >
      <Icon
        type={paid ? "check" : "gift"}
        style={{ color: "#48ac16", marginRight: 3 }}
      />
      {freire(paid ? texts.PAID : texts.FREE)}
    </Tag>
  );
};

const PayChargeButton = ({ freire, onConfirm }) => {
  const [focused, setFocused] = useState(false);

  const toggleFocused = () => {
    setFocused((focused) => !focused);
  };

  const blur = () => {
    setFocused(false);
  };

  return (
    <Show when={"show-user-charges-reprocess"}>
      <Popconfirm
        placement="topRight"
        title={freire(texts.CONFIRM_PAY_CHARGE)}
        okType="danger"
        okText={freire(texts.YES)}
        cancelText={freire(texts.NO)}
        onConfirm={onConfirm}
        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
      >
        <Tooltip
          placement="topRight"
          title={freire(texts.PAY_CHARGE)}
          {...(focused && { visible: false })}
        >
          <Button
            shape="circle"
            size="small"
            ghost
            type="danger"
            onClick={toggleFocused}
            onMouseLeave={blur}
          >
            <Icon type="sync" />
          </Button>
        </Tooltip>
      </Popconfirm>
    </Show>
  );
};

const Charges = ({ userID }) => {
  const { freire } = useFreire();

  const [loading, setLoading] = useState(false);
  const [charges, setCharges] = useState([]);

  const CSVLinkRef = useRef(null);
  const [chargesCsv, setChargesCsv] = useState([]);
  const [headersCsv, setHeadersCsv] = useState([]);

  const downloadExcel = async () => {
    try {
      setChargesCsv(formatExcel({ docs: charges, freire }));
      setHeadersCsv(getHeaders({ freire }));
      setTimeout(() => {
        if (CSVLinkRef.current) {
          CSVLinkRef.current.link.click();
        } else {
          notification.error({
            message: "Ops...",
            description: freire(texts.ERROR_DOWNLOAD_FILE),
          });
        }
      }, 500);
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_CREATE_FILE),
      });
    }
  };

  const getUserCharges = async () => {
    try {
      setLoading(true);
      setCharges([]);

      const { data } = await api.get(`users_charged/${userID}`);

      setCharges(data);
    } catch (error) {
      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.ERROR_GET_CHARGES_USERS),
      });
    } finally {
      setLoading(false);
    }
  };

  useOnMount(getUserCharges);

  const couponAttributes = (charge) => {
    const attrs = {};

    if (
      charge.coupon2 instanceof Object &&
      Object.keys(charge.coupon2).length > 0
    ) {
      attrs.value = (
        <span
          {...(charge.revertedCoupon && {
            style: { opacity: 0.75, textDecoration: "line-through" },
          })}
        >
          {charge.coupon2.cod}
        </span>
      );
      attrs.tooltip = (
        <div>
          <div>
            <div>
              <strong>{freire(texts.CAMPAIGN)}:</strong>{" "}
              <Copy>{charge.campaign?.name}</Copy>
            </div>
          </div>
          <Show when={"developer"}>
            <br />
            <div>
              <div>
                <strong>raw(coupon):</strong> <Copy>{charge.coupon2._id}</Copy>
              </div>
              <div>
                <strong>raw(campaign):</strong>{" "}
                <Copy>{charge.campaign?._id}</Copy>
              </div>
            </div>
          </Show>
          {charge.campaign instanceof Object && (
            <>
              <br />
              <div>
                {/* TODO: rever segunda div */}
                <div>
                  <a
                    // style={{ textDecoration: "underline" }}
                    rel="noopener noreferrer"
                    target="_blank"
                    // TODO: some querystring lib
                    href={`/dashboard/coupons/${charge.campaign._id}`}
                  >
                    {freire({ pt: "Ver cupons da campanha" })}
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      );
    }

    if (charge.campaign?.benefit instanceof Object) {
      if (!attrs.value) {
        attrs.value = (
          <span
            {...(charge.revertedCoupon && {
              style: { opacity: 0.75, textDecoration: "line-through" },
            })}
          >
            ✅
          </span>
        );
      }
      attrs.extraItems = (
        <BenefitDescription
          benefit={charge.campaign.benefit}
          currency={charge.currency}
          freire={freire}
        />
      );
    }

    return attrs;
  };

  const payUserCharge = async (chargeId) => {
    try {
      setLoading(true);

      await api.post(`/chargerHistory/${chargeId}/payment`);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.PAY_CHARGE_SUCCESS),
      });

      await getUserCharges();
    } catch (error) {
      setLoading(false);

      const description =
        error?.response?.data?.message || error?.response?.data;

      notification.error({
        message: freire(texts.OOPS),
        description:
          description ?? error.message ?? freire(texts.PAY_CHARGE_ERROR),
      });
    }
  };

  const hasIdlePayment = (charge) => {
    return (
      charge.stationSnapshot?.idleFee?.enabled &&
      charge.meterValues?.lastTimestamp &&
      charge.startIdlePeriod
    );
  };

  const totalChargesValue = charges.reduce((acc, charge) => 
    acc + (charge.chargeValue) / 100
  , 0);

  return (
    <div>
      {loading ? (
        <Spin
          style={{ display: "block", marginTop: 25 }}
          tip={freire(texts.LOADING_USER_CHARGES)}
        />
      ) : (
        <>
          <span className="drawerInfoTitle">
            {freire(texts.CHARGES_COUNT(charges.length))} - {
              formatters.currency(
                freire.userLanguage,
                charges[0]?.currency
              )(totalChargesValue)
            }
          </span>
          <div>
            <CSVLink
              ref={CSVLinkRef}
              data={chargesCsv}
              headers={headersCsv}
              filename={freire(
                texts.CSV_FILE_NAME_HISTORY(
                  moment().format(freire(texts.MONTH_TIME)),
                  userID
                )
              )}
            />
            <button
              style={{
                color: "#1890ff",
                cursor: "pointer",
                paddingLeft: 10,
                fontSize: 17,
                padding: "8px 16px",
                display: "inline-block",
                float: "right",
                marginTop: "-31px",
                border: "none",
                background: "none",
              }}
              onClick={() => downloadExcel()}
            >
              {freire(texts.DOWNLOAD_CHARGES_CSV)}
              &nbsp;&nbsp;
              <Icon type="download" />
            </button>
          </div>
          {charges.length > 0 ? (
            charges.map((charge, index) => (
              <Card
                key={charge._id}
                title={
                  <>
                    <Badge
                      count={charges.length - index}
                      overflowCount={Number.MAX_SAFE_INTEGER}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #cececd inset",
                        width: 43,
                        marginRight: 24,
                      }}
                      title=" "
                    />
                    <Popover
                      content={
                        <div>
                          <div>
                            <div>
                              <strong>{freire(texts.CHARGE_ID)}:</strong>{" "}
                              <Copy>{charge.transactionID}</Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.STATION)}:</strong>{" "}
                              <Copy>{charge.station?.name}</Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.STATION_ID)}:</strong>{" "}
                              <Copy>{charge.stationID}</Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.CONNECTOR_ID)}:</strong>{" "}
                              <Copy>{charge.connectorID}</Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.ADDRESS)}:</strong>{" "}
                              <Copy>
                                {charge.address ?? charge.station?.address}
                              </Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.COMPANY)}:</strong>{" "}
                              <Copy>{charge.company?.name}</Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.PARENT_COMPANY)}:</strong>{" "}
                              <Copy>{charge.parentCompany?.name}</Copy>
                            </div>
                          </div>
                          <br />
                          <div>
                            <div>
                              <strong>{freire(texts.APP)}:</strong>{" "}
                              <Copy>
                                {!charge.rfidTagCod &&
                                  getAppByAlias(charge.appName).name}
                              </Copy>
                            </div>
                            <div>
                              <strong>{freire(texts.RFID_TAG)}:</strong>{" "}
                              <Copy>{charge.rfidTagCod}</Copy>
                            </div>
                          </div>
                          <Show when={"developer"}>
                            <br />
                            <div>
                              <div>
                                <strong>{freire(texts.COUNTRY_CODE)}:</strong>{" "}
                                <Copy>{charge.country}</Copy>
                              </div>
                              <div>
                                <strong>{freire(texts.CURRENCY_CODE)}:</strong>{" "}
                                <Copy>{charge.currency}</Copy>
                              </div>
                            </div>
                          </Show>
                          {typeof charge.station?.lat === "number" &&
                            typeof charge.station.lng === "number" && (
                              <>
                                <br />
                                <div>
                                  {/* TODO: rever segunda div */}
                                  <div>
                                    <a
                                      // style={{ textDecoration: "underline" }}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      // TODO: some querystring lib
                                      href={`https://www.google.com/maps/search/?api=1&query=${charge.station.lat},${charge.station.lng}`}
                                    >
                                      {freire({ pt: "Ver no Google Maps" })}
                                    </a>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      }
                    >
                      {charge.rfidTagCod ? (
                        <Icon
                          type="tags"
                          style={{ marginRight: 8, color: "#a3a3a3" }}
                        />
                      ) : (
                        <img
                          src={getAppByAlias(charge.appName).favicon}
                          width={18}
                          style={{ marginRight: 8, marginBottom: 2 }}
                          alt={getAppByAlias(charge.appName).name}
                        />
                      )}
                      <span>
                        {charge.station?.name ?? "----"} (
                        {charge.company?.name ?? "----"})
                      </span>
                    </Popover>
                  </>
                }
                extra={
                  <>
                    {!charge.paymentSuccess && (
                      <PayChargeButton
                        freire={freire}
                        onConfirm={() => payUserCharge(charge._id)}
                      />
                    )}
                    <Show when={"show-btn-ocpp"}>
                      <Tooltip
                        placement="topRight"
                        title={freire(texts.SEE_ON_OCPP(charge.transactionID))}
                      >
                        <Button
                          shape="circle"
                          size="small"
                          ghost
                          type="primary"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            charge.centralSystem
                              ? `/dashboard/ocpp/transactions/${charge.transactionID}`
                              : `https://ocpp.tupinambaenergia.com.br/steve/manager/transactions/details/${charge.transactionID}`
                          }
                        >
                          <Icon type="link" />
                        </Button>
                      </Tooltip>
                    </Show>
                    {charge.receiptFilePath && (
                      <Tooltip
                        placement="top"
                        title={freire(texts.SEE_RECEIPT)}
                      >
                        <a
                          href={charge.receiptFilePath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            shape="circle"
                            size="small"
                            ghost
                            type="primary"
                            style={{ marginRight: 8 }}
                          >
                            <Icon type="download" />
                          </Button>
                        </a>
                      </Tooltip>
                    )}
                  </>
                }
                className="chargeCard"
                headStyle={{ borderBottomColor: "#CECECD" }}
                bodyStyle={{ paddingTop: 8 }}
              >
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire(texts.ENERGY)}:`}
                  // extraItems={
                  //   <PaymentChargeDescription charge={charge} freire={freire} />
                  // }
                  {...(charge.stopValue !== null && {
                    value: freire(
                      texts.KWH(
                        charge.stopValue.toLocaleString(freire.userLanguage, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      )
                    ),
                  })}
                  bold={false}
                />
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire(texts.ENERGY_VALUE)}:`}
                  value={
                    <span>
                      {formatters.currency(
                        freire.userLanguage,
                        charge.currency
                      )((charge.chargeValue - (charge.idleValue ?? 0)) / 100)}
                    </span>
                  }
                  bold={false}
                />
                {hasIdlePayment(charge) && (
                  <InfoRow
                    labelSpan={7}
                    valueSpan={17}
                    label={`${freire(texts.IDLE_VALUE)}:`}
                    value={
                      <span>
                        {formatters.currency(
                          freire.userLanguage,
                          charge.currency
                        )(charge.idleValue / 100)}
                      </span>
                    }
                    bold={false}
                  />
                )}
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire(texts.COUPON)}:`}
                  bold={false}
                  {...couponAttributes(charge)}
                />
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire({ pt: "Avaliação" })}:`}
                  bold={false}
                  {...(charge.stopValue !== null &&
                    charge.nps instanceof Object &&
                    typeof charge.nps.rating === "number" && {
                      value: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              height: 9,
                              width: 9,
                              borderRadius: "50%",
                              marginRight: 8,
                              backgroundColor:
                                satismeterResults[
                                  satismeterSurveyMetrics[
                                    satismeterProject.surveys[
                                      charge.nps.surveyType
                                    ].metric
                                  ].getResult(charge.nps.rating)
                                ].color,
                            }}
                          />
                          {freire({
                            pt: `${freire(
                              satismeterSurveyMetrics[
                                satismeterProject.surveys[charge.nps.surveyType]
                                  .metric
                              ].text
                            )} ${charge.nps.rating}${
                              charge.nps.comment ? " com comentário" : ""
                            }`,
                          })}
                        </div>
                      ),
                      tooltip: (
                        <div>
                          <div>
                            <div>
                              <strong>{freire({ pt: "Projeto" })}:</strong>{" "}
                              <Copy>{satismeterProject.name}</Copy>
                            </div>
                            <div>
                              <strong>{freire({ pt: "Pesquisa" })}:</strong>{" "}
                              <Copy>
                                {
                                  satismeterProject.surveys[
                                    charge.nps.surveyType
                                  ].name
                                }
                              </Copy>
                            </div>
                          </div>
                          <br />
                          <div>
                            <div>
                              <strong>{freire({ pt: "Resultado" })}:</strong>{" "}
                              <Copy>
                                {freire(
                                  satismeterResults[
                                    satismeterSurveyMetrics[
                                      satismeterProject.surveys[
                                        charge.nps.surveyType
                                      ].metric
                                    ].getResult(charge.nps.rating)
                                  ].text
                                )}
                              </Copy>
                            </div>
                            <div>
                              <strong>{freire({ pt: "Comentário" })}:</strong>{" "}
                              <Copy>{charge.nps.comment}</Copy>
                            </div>
                          </div>
                          <br />
                          <div>
                            {/* TODO: rever segunda div */}
                            <div>
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                // TODO: some querystring lib
                                href={`https://app.satismeter.com/projects/${
                                  satismeterProject.projectId
                                }/campaigns/${
                                  satismeterProject.surveys[
                                    charge.nps.surveyType
                                  ].surveyId
                                }/dashboard?filters=%5B%7B%22attribute%22%3A%22user.traits.idDaResposta%22%2C%22operator%22%3A%22eq%22%2C%22value%22%3A%22${
                                  charge.nps.npsId
                                }%22%2C%22type%22%3A%22string%22%7D%5D&startDate=${moment
                                  .utc(charge.startDateTime)
                                  .subtract(1, "day")
                                  .format("YYYY-MM-DD")}&endDate=${moment
                                  .utc(charge.stopDateTime)
                                  .add(1, "day")
                                  .format("YYYY-MM-DD")}`}
                              >
                                {freire({ pt: "Ver no Satismeter" })}
                              </a>
                            </div>
                          </div>
                        </div>
                      ),
                    })}
                />
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire(texts.VALUE)}:`}
                  value={
                    charge.stopValue === null ? (
                      "----"
                    ) : (
                      <>
                        <span>
                          {formatters.currency(
                            freire.userLanguage,
                            charge.currency
                          )(charge.chargeValue / 100)}
                        </span>
                        {charge.discountValue > 0 && (
                          <>
                            {" "}
                            <DiscountValue charge={charge} freire={freire} />
                          </>
                        )}
                      </>
                    )
                  }
                  {...(charge.stopValue !== null
                    ? {
                        extraItems: (
                          <>
                            <PaymentStatus
                              chargeValue={charge.chargeValue}
                              freire={freire}
                              paymentSuccess={charge.paymentSuccess}
                              posPayment={charge.posPayment}
                            />
                            {
                              // eslint-disable-next-line eqeqeq
                              charge.paymentMethod != undefined && (
                                <Tag
                                  color="blue"
                                  className="paymentStatusTag"
                                  style={{ paddingLeft: 5 }}
                                >
                                  <Icon
                                    type="credit-card"
                                    style={{
                                      color: "#1890ff",
                                      marginLeft: 3,
                                      marginRight: 5,
                                    }}
                                  />
                                  {BRANDS[charge.paymentMethod.brand] ??
                                    charge.paymentMethod.brand ??
                                    "Crédito"}
                                  {charge.paymentMethod.suffix !== undefined &&
                                    ` ${charge.paymentMethod.suffix}`}
                                </Tag>
                              )
                            }
                          </>
                        ),
                      }
                    : {
                        // TODO?: refatorar a condição acima
                        extraItems: (
                          <Tag
                            // color="green"
                            color="orange"
                            className="paymentStatusTag"
                            style={{ paddingLeft: 6 }}
                          >
                            <Icon
                              component={() => (
                                <PowerPlugIcon
                                  // color="#48ac16"
                                  color="#eb7c05"
                                />
                              )}
                              // type="loading"
                              style={{
                                marginRight: 4,
                              }}
                            />
                            {freire(texts.CHARGING)}
                          </Tag>
                        ),
                      })}
                  bold={false}
                />
                <InfoRow
                  labelSpan={7}
                  valueSpan={17}
                  label={`${freire(texts.DURATION)}:`}
                  value={`Inicio: ${moment(charge.startDateTime).format(
                    "L LTS"
                  )}`}
                  extraItems={
                    <Tag
                      className="paymentStatusTag"
                      style={{ paddingLeft: 6 }}
                    >
                      <Icon
                        type="clock-circle"
                        style={{
                          marginRight: 5,
                        }}
                      />
                      {formatters.duration(freire)(
                        moment(charge.stopDateTime ?? undefined).diff(
                          charge.startDateTime,
                          "hours"
                        ) === 0
                          ? moment.duration(
                              moment(charge.stopDateTime ?? undefined).diff(
                                charge.startDateTime,
                                "minutes"
                              ),
                              "minutes"
                            )
                          : moment.duration(
                              moment(charge.stopDateTime ?? undefined).diff(
                                charge.startDateTime,
                                "hours"
                              ),
                              "hours"
                            )
                      )}
                    </Tag>
                  }
                  {...(charge.stopValue !== null && {
                    // TODO: arrumar para todos esses exibirem do mesmo jeito a duração
                    tooltip: freire(
                      texts.AGO(
                        formatters.duration(freire)(
                          moment().diff(charge.stopDateTime, "hours") === 0
                            ? moment.duration(
                                moment().diff(charge.stopDateTime, "minutes"),
                                "minutes"
                              )
                            : moment.duration(
                                moment().diff(charge.stopDateTime, "hours"),
                                "hours"
                              )
                        )
                      )
                    ),
                  })}
                  bold={false}
                />
                {hasIdlePayment(charge) && (
                  <IdleStartRow charge={charge} texts={texts} freire={freire} />
                )}
              </Card>
            ))
          ) : (
            <Empty
              description={freire(texts.USER_EMPTY_CHARGES)}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Charges;
