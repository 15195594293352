import axios from "axios";
import {
  setLanguageInterceptor,
  setTokenRequestInterceptor,
} from "./interceptors";

const apiOcpi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/proxy-ocpi`,
  withCredentials: true,
  transitional: {
    clarifyTimeoutError: true,
  },
});

apiOcpi.interceptors.request.use(setTokenRequestInterceptor);
apiOcpi.interceptors.request.use(setLanguageInterceptor);

export default apiOcpi;
