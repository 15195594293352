import React, { useCallback, useEffect, useState } from "react";
import { Button, Drawer, notification } from "antd";
import { withRouter } from "react-router-dom";
import Charges from "./Charges";
import Details from "./Details";
import Coupons from "./Coupons";
import * as texts from "../../../locales";
import { useFreire } from "../../../../../utils/freireContext";
import { Show } from "../../../../../contexts/PermissionContext";
import { Tags } from "./Tags";
import api from "../../../../../services/api";

const UserDetailsAndCharges = ({ user, visible, onClose }) => {
  const { freire } = useFreire();

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const getUserDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`admin/user/${user.userID}`);

      setUserDetails(data);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_DETAILS_USER),
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userID]);

  useEffect(() => {
    if (user && user.userID) {
      getUserDetails();
    }
  }, [user, getUserDetails]);

  return (
    <Drawer
      title={`${user.displayName} (${user.email})`}
      width={"min(max(min(30%, 750px), 700px), 100%)"}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {visible && (
        <>
          <Show when={"show-user-personal-data"}>
            <Details userID={user.userID} />
          </Show>
          <Coupons userData={userDetails} loadingModal={loading} />
          <Tags
            userData={userDetails}
            loadingModal={loading}
            getUserDetails={getUserDetails}
          />
          <Charges userID={user.userID} />
        </>
      )}

      <div className="drawerFooter">
        <Button onClick={onClose}>{freire(texts.CLOSE)}</Button>
      </div>
    </Drawer>
  );
};

export default withRouter(UserDetailsAndCharges);
