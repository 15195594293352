export const ERROR_CUSTOMER_TYPE = {
  pt: "Não foi possível carregar os tipos de cliente",
  es: "No se pueden cargar tipos de clientes",
  en: "Unable to load client types",
};

export const ERROR_CONTRACTED_PLAN = {
  pt: "Não foi possível carregar os planos contratados",
  es: "No se pueden cargar los planes contratados",
  en: "Unable to load contracted plans",
};

export const ERROR_LOAD_PACK_ICONS = {
  pt: "Não foi possível carregar os pacotes de ícones",
  es: "No fue posible cargar los paquetes de iconos",
  en: "It was not possible to load the icon packages",
};

export const ERROR_LIST_COMPANIES = {
  pt: "Não foi possível carregar a lista de empresas",
  es: "No fue posible cargar la lista de empresas",
  en: "It was not possible to load the company list",
};

export const ERROR_LIST_STATIONS = {
  pt: "Não foi possível carregar a lista de estações",
  es: "No fue posible cargar la lista de estaciones",
  en: "It was not possible to load the station list",
};

export const ERROR_LIST_COUPONS = {
  pt: "Não foi possível carregar a lista de cupons",
  es: "No fue posible cargar la lista de cupones",
  en: "It was not possible to load the coupon list",
};

export const ERROR_LIST_REPORTS = {
  pt: "Não foi possível carregar a lista de relatórios",
  es: "No fue posible cargar la lista de informes",
  en: "It was not possible to load the report list",
};

export const ERROR_LIST_COUNTRIES = {
  pt: "Não foi possível carregar a lista de países",
  es: "No fue posible cargar la lista de países",
  en: "It was not possible to load the country list",
};

export const ERROR_LIST_RECIPIENTS = {
  pt: "Não foi possível carregar a lista de recebedores",
  es: "No fue posible cargar la lista de destinatarios",
  en: "It was not possible to load the recipient list",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Está bien!",
  en: "All set!",
};

export const COMPANY_ADD_SUCCESS = {
  pt: "Empresa adicionada com sucesso!",
  es: "¡Empresa agregada con éxito!",
  en: "Successfully added company!",
};

export const WEN_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const COMPANIES = {
  pt: "Empresas",
  es: "Empresas",
  en: "Companies",
};

export const ADD_COMPANY = {
  pt: "Adicione uma empresa",
  es: "Agregar una empresa",
  en: "Add a company",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const COMPANY_DOCUMENT = {
  pt: "Número ou Identificação",
  es: "Número o Identificación",
  en: "Company document",
};

export const SELECT_DOCUMENT_TYPE = {
  pt: "Selecione o tipo de documento",
  es: "Seleccione el tipo de documento",
  en: "Select document type",
};

export const TYPE_OF_COSTUMER = {
  pt: "Tipo de cliente:",
  es: "Tipo de cliente",
  en: "Type of customer",
};

export const PLACEHOLDER_TYPE_OF_COSTUMER = {
  pt: "Selecione um tipo de cliente",
  es: "Seleccione un tipo de cliente",
  en: "Select a type of customer",
};

export const CONTRACTED_PLAN = {
  pt: "Plano contratado:",
  es: "Plan contratado:",
  en: "Contracted plan:",
};

export const PLACEHOLDER_CONTRACTED_PLAN = {
  pt: "Selecione o plano contratado",
  es: "Selecciona el plan contratado",
  en: "Select the contracted plan",
};

export const MONTHLY_FEE = (currency) => ({
  pt: `mensalidade (${currency}):`,
  es: `Cuota mensual (${currency}):`,
  en: `Monthly fee (${currency}):`,
});

export const NUMBER_CHARGERS = {
  pt: "Quantidade de carregadores:",
  es: "Cantidad de cargadores",
  en: "Number of chargers:",
};

export const TOTAL_MONTHLY_AMOUNT = (currency) => ({
  pt: `Valor total mensal (${currency}):`,
  es: `Total mensual (${currency}):`,
  en: `Total monthly amount (${currency}):`,
});

export const TAX_MODEL = {
  pt: "Modelo fiscal:",
  es: "Modelo fiscal:",
  en: "Tax model:",
};

export const TUPI_RATE = {
  pt: "Taxa Tupi (%):",
  es: "Tarifa Tupi (%):",
  en: "Tupi Rate (%):",
};

export const PLACEHOLDER_TAX_MODEL = {
  pt: "Selecione o modelo fiscal",
  es: "Seleccionar modelo fiscal",
  en: "Select tax model",
};

export const PIPEDRIVE_ID = {
  pt: "ID Pipedrive",
  es: "ID Pipedrive",
  en: "Pipedrive ID",
};

export const COUNTRY = {
  pt: "País",
  es: "País",
  en: "Country",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const STATE = {
  pt: "Estado",
  es: "Estado",
  en: "State",
};

export const ZIP_CODE = {
  pt: "CEP",
  es: "Código Postal",
  en: "Zip code",
};

export const ADD = {
  pt: "Adicionar",
  es: "Agregar",
  en: "Add",
};

export const LIST = {
  pt: "Lista",
  es: "Lista",
  en: "List",
};

export const REQUIRED = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const PARENT_COMPANY = {
  pt: "Empresa Mãe",
  es: "Empresa Matriz",
  en: "Parent Company",
};

export const IS_PARENT_COMPANY = {
  pt: "É uma empresa mãe?",
  es: "¿Es una empresa mãe?",
  en: "Is a parent company?",
};

export const SELECT_PARENT_COMPANY = {
  pt: "Selecione a empresa mãe",
  es: "Seleccione la empresa madre",
  en: "Select parent company",
};

export const PINS_CUSTOMIZED = {
  pt: "Pin customizado?",
  es: "¿Pin personalizado?",
  en: "Customized pin?",
};

export const COUPON = {
  pt: "Cupons",
  es: "Cupones",
  en: "Coupons",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciónes",
  en: "Stacions",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const CHOOSE_PIN = {
  pt: "Escolha o Pin",
  es: "Elija el Pin",
  en: "Choose Pin",
};

export const PATTERN = {
  pt: "Padrão",
  es: "Estándar",
  en: "Default",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const ADD_MORE = {
  pt: "+ Adicionar",
  es: "+ Añadir",
  en: "+ Add",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const EMAIL_LIST = {
  pt: "Lista de e-mails",
  es: "Lista de correo electrónico",
  en: "E-mail list",
};

export const EMAIL = {
  pt: "E-mail",
  es: "Correo Electrónico",
  en: "E-mail",
};

export const EMAIL_REPORT_INFO = {
  pt: "Os e-mails nessa lista receberão relatórios mensais com atualizações das estações ",
  es: "Los correos electrónicos de esta lista recibirán informes mensuales con actualizaciones de la estación ",
  en: "Emails on this list will receive monthly reports with station updates ",
};

export const EXAMPLE = {
  pt: "exemplo",
  es: "Ejemplo",
  en: "example",
};

export const EMAIL_REPORT_INFO_TWO = {
  pt: "- consumo de kWh, número de usuários, quantidade e tempo médio de cargas - e a utilização dos cupons ",
  es: "- consumo de kWh, número de usuarios, cantidad y tiempo promedio de cargas - y el uso de los cupones ",
  en: "- kWh consumption, number of users, quantity and average charging time - and the use of coupons ",
};

export const EMAIL_REPORT_INFO_THREE = {
  pt: " incluindo alertas de consumo da cota ",
  es: " incluyendo alertas de consumo del cupón",
  en: " including quota consumption alerts ",
};

export const FINANCIAL = {
  pt: "Financeiro",
  es: "Financiero",
  en: "Financial",
};

export const REPORT = {
  pt: "Relatórios",
  es: "Reporte",
  en: "Report",
};

export const INFORM_VALID_EMAIL = {
  pt: "Informe um e-mail válido",
  es: "Introduzca un correo electrónico válido",
  en: "Inform a valid e-mail",
};

export const INPUT_EMAILS = {
  pt: "Digite os e-mails",
  es: "Escriba los correos electrónicos",
  en: "Type the e-mails",
};

export const INFO = {
  pt: "Informações",
  es: "Información",
  en: "Information",
};

export const CUSTOMIZATION = {
  pt: "Personalização",
  es: "Personalización",
  en: "Customization",
};

export const EMAIL_SUCESS = {
  pt: "Sucesso ao adicionar email",
  es: "Éxito al agregar el correo electrónico",
  en: "Success adding email",
};

export const EMAIL_ERROR = {
  pt: "Erro ao adicionar email",
  es: "Error al agregar el correo electrónico",
  en: "Error adding email",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const CHARGE_PROCESSING_FEE = {
  pt: "Cobrado de taxas",
  es: "Cobro de tarifas",
  en: "Charged fees",
};

export const CLEAN = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const DELETE = {
  pt: "Excluir",
  es: "Eliminar",
  en: "Delete",
};

export const EDIT_COMPANY = {
  pt: "Editar empresa",
  es: "Editar empresa",
  en: "Edit company",
};

export const ERROR_LOAD_WHITELABEL_LIST = {
  pt: "Não foi possível carregar a lista de white labels",
  es: "No fue posible cargar la lista de white Labels",
  en: "It was not possible to load the white labels list",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const LIABLE = {
  pt: "Risco de chargeback",
  es: "Riesgo de contracargo",
  en: "Chargeback risk",
};

export const MANAGEMENT = {
  pt: "Administração",
  es: "Administración",
  en: "Administration",
};

export const NEW = {
  pt: "Nova",
  es: "Nueva",
  en: "New",
};

export const PAYMENT_SPLIT_RULE = {
  pt: "Regra de split de pagamentos",
  es: "Regla de división de pagos",
  en: "Payment split rule",
};

export const PERCENTAGE = {
  pt: "percentage",
  es: "porcentaje",
  en: "percentage",
};

export const RECIPIENT = {
  pt: "Recebedor",
  es: "Receptor",
  en: "Recipient",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const SEARCH_BY_COMPANY = {
  pt: "Pesquise pela empresa...",
  es: "Busque por la empresa...",
  en: "Search by company...",
};

export const SELECT_RECIPIENT = {
  pt: "Selecione um recebedor",
  es: "Seleccione un receptor",
  en: "Select a recipient",
};

export const SELECT_STATION = {
  pt: "Selecione a estação",
  es: "Seleccione la estación",
  en: "Select a station",
};

export const SELECT_STATUS = {
  pt: "Selecione o status",
  es: "Seleccione el estado",
  en: "Select status",
};

export const SELECT_TYPE = {
  pt: "Selecione o tipo",
  es: "Seleccione el tipo",
  en: "Select type",
};

export const SELECT_WHITELABEL = {
  pt: "Selecione o white label",
  es: "Seleccione la etiqueta blanca",
  en: "Select the white label",
};

export const SHOW_DETAILS_EDIT = {
  pt: "Ver detalhes | Editar",
  es: "Ver detalles | Editar",
  en: "See details | Edit",
};

export const TOTAL = {
  pt: "Total",
  es: "Total",
  en: "Total",
};

export const TOTAL_NOT_FULL = {
  pt: "Total não chegou a 100%",
  es: "El total no alcanzó el 100%",
  en: "Total did not reach 100%",
};

export const URL_POWERBI = {
  pt: "Url PowerBI",
  es: "Url PowerBI",
  en: "Url PowerBI",
};

export const USER = {
  pt: "Usuário",
  es: "Usuario",
  en: "User",
};

export const WHITE_LABEL = {
  pt: "White Label",
  es: "Etiqueta blanca",
  en: "White Label",
};

export const OCPI_PARTY_ID = {
  pt: "ID de parceiro OCPI (party_id)",
  es: "OCPI Party ID",
  en: "OCPI Party ID",
}
