import { getCompanyCurrentUser } from "../services/auth";
import {
  BRAND,
  BRS,
  TEMP_CIA_CHARGE,
  TEMP_ENERGISA,
  TEMP_PHB,
} from "../config";
import { FormComponentProps } from "antd/lib/form";

export const getUserPermissionToUpdatePricePerHour =
  async (): Promise<boolean> => {
    const userCompany = await getCompanyCurrentUser();

    const tupiUser = userCompany?.id === BRAND && userCompany?.parentCompanyID === BRAND;
    const brsUser = userCompany?.id === BRS || userCompany?.parentCompanyID === BRS;
    const phbUser = userCompany?.id === TEMP_PHB || userCompany?.parentCompanyID === TEMP_PHB;
    const ciaChargeUser =
      userCompany?.id === TEMP_CIA_CHARGE || userCompany?.parentCompanyID === TEMP_CIA_CHARGE;
    const energisaUser =
      userCompany?.id === TEMP_ENERGISA || userCompany?.parentCompanyID === TEMP_ENERGISA;

    const userHasPermission =
      tupiUser || brsUser || phbUser || ciaChargeUser || energisaUser;

    return userHasPermission;
  };

export const shouldShowPricePerHour = (
  form: FormComponentProps["form"],
  userHasPermission: boolean
): boolean => {
  const validPaymentCharge =
    form.getFieldValue("paymentCharge.enabled") &&
    form.getFieldValue("paymentCharge.method") !== "" &&
    form.getFieldValue("paymentCharge.method") !== "Time";

  const shouldBeShow = validPaymentCharge && userHasPermission;
  return shouldBeShow;
};
