import React, { Component } from "react";
//import "./index.css";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { FreireContext } from "../../utils/freireContext";
import * as texts from "./locales";

class OkrPowerBi extends Component {
  static contextType = FreireContext;

  render() {
    const { freire } = this.context;

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[freire(texts.ADMIN), freire(texts.PAINEL_OKR)]}
        />
        <div>
          <iframe
            title="PowerBiOkr"
            width="100%"
            height="800"
            src="https://lookerstudio.google.com/embed/u/0/reporting/6122b60e-c3af-4804-94da-0eb65fe92c40/page/klAcE"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default withRouter(OkrPowerBi);
