export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const STATUS = {
  pt: "Status",
  es: "Estado",
  en: "Status",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const OPTIONS = {
  pt: "Opções",
  es: "Opciones",
  in: "Options",
};

export const NO_HAVE = {
  pt: "Não há",
  es: "No hay",
  en: "None",
};

export const TOTAL_CHARGED = {
  pt: "Total Carregado",
  es: "Total Cargado",
  en: "Total loaded",
};

export const UPKEEP = {
  pt: "Em manutenção",
  es: "En mantenimiento",
  en: "Under maintenance",
};

export const COPY = {
  pt: "Copiar",
  es: "Copiar",
  en: "Copy",
};

export const DISABLED = {
  pt: "Desativada",
  es: "Desactivada",
  en: "Disabled",
};

export const VISIBILITY = {
  pt: "Visibilidade",
  es: "Visibilidad",
  en: "Visibility",
};

export const HIDDEN = {
  pt: "Oculto",
  es: "Oculto",
  en: "Hidden",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const ALL = {
  pt: "Todos",
  es: "Todos",
  en: "All",
};

export const PLUGS = {
  pt: "Plugs",
  es: "Enchufe",
  en: "Plugs",
};

export const PAYMENT = {
  pt: "Pagamento",
  es: "Pago",
  en: "Payment",
};

export const RESERVE = {
  pt: "Reserva",
  es: "Reserva",
  en: "Reservation",
};

export const CHARGE = {
  pt: "Carga",
  es: "Carga",
  en: "Charge",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SHOW_QRCODE = {
  pt: "Ver QrCode",
  es: "Ver QrCode",
  en: "View QrCode",
};
export const QRCODE = {
  pt: "QrCode",
  es: "QrCode",
  en: "QrCode",
};
export const SHOW_DETAILS = {
  pt: "Ver detalhes",
  es: "Ver detalles",
  en: "View details",
};

export const EDIT = {
  pt: "Editar",
  es: "Editar",
  en: "Edit",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const QUESTION_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Desea realmente BORRAR?",
  en: "Do you really want to DELETE?",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const FIXED = {
  pt: "Fixo",
  es: "Fijo",
  en: "Fixed",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡De acuerdo!",
  en: "All right!",
};

export const STATION_EDITED = {
  pt: "Estação editada com sucesso!",
  es: "¡Estación editada con éxito!",
  en: "Station edited successfully!",
};

export const STATION_UPKEEP = (upkeep) => ({
  pt: `Status de manutenção ${upkeep ? "desativado" : "ativado"} com sucesso!`,
  // TODO: translate es
  // TODO: translate en
});

export const STATION_ADDED = {
  pt: "Estação adicionada com sucesso!",
  es: "¡Estación agregada con éxito!",
  en: "Station added successfully!",
};

export const STATION_DELETED = {
  pt: "Estação deletada.",
  es: "Estación eliminada.",
  en: "Station deleted.",
};

export const WENT_WRONG = {
  pt: "Algo deu errado!",
  es: "¡Algo ha salido mal!",
  en: "Something went wrong!",
};

export const ERROR_DOWNLOAD_FILE = {
  pt: "Não foi possível fazer o download do arquivo",
  es: "No fue posible realizar la descarga del archivo",
  en: "Unable to download file",
};

export const ERROR_GENERATE_FILE = {
  pt: "Não foi possível gerar o arquivo para download",
  es: "No fue posible generar el archivo para descarga",
  en: "Unable to generate file for download",
};

export const ERROR_GET_STATIONS_LOCATIONS = {
  pt: "Erro ao buscar locais de estação",
  es: "Error al buscar lugares de estación",
  en: "Error looking for station locations",
};

export const ERROR_GET_STATIONS_MODELS = {
  pt: "Erro ao buscar modelos de estação",
  es: "Error al buscar modelos de estación",
  en: "Error looking for station models",
};

export const ERROR_GET_STATIONS_TYPES = {
  pt: "Erro ao buscar tipos de estação",
  es: "Error al buscar tipos de estación",
  en: "Error looking for station types",
};

export const ERROR_INFO_RESERVE_PAYMENT = {
  pt: "Informe o valor do pagamento da reserva",
  es: "Informe el valor del pago de la reserva",
  en: "Enter the payment amount of the reservation",
};

export const ERROR_SELECT_STATIONS = {
  pt: "Selecione pelo menos uma estação",
  es: "Seleccione al menos una estación",
  en: "Select at least one station",
};

export const ERROR_INFO_CHARGE_PAYMENT_METHOD = {
  pt: "Informe o método do pagamento da carga",
  es: "Informe el método del pago de la carga",
  en: "Specify the method of payment of the charge",
};

export const ERROR_IDLE_FEE_INFO = {
  pt: "Informe a taxa de ociosidade e o tempo de carência",
  es: "Ingrese la tarifa de inactividad y el tiempo de espera",
  en: "Enter the idle fee and the grace period",
};

export const ERROR_INFO_CHARGE_PAYMENT = {
  pt: "Informe o valor do pagamento do método da carga",
  es: "Informe el valor del pago del método de la carga",
  en: "Specify the payment amount of the charge method",
};

export const ERROR_GET_PLUGS = {
  pt: "Erro ao buscar plugs",
  es: "Error al buscar plugs",
  en: "Error looking for plugs",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Station",
};

export const CONNECTED = {
  pt: "Conectada",
  es: "Conectada",
  en: "Connected",
};

export const CONNECTED_QUESTION = {
  pt: "Conectada na rede?",
  es: "¿Conectada?",
  en: "Connected?",
};

export const BATCH_OPERATIONS = {
  pt: "Operações em lote",
  es: "Operaciones en lote",
  en: "Batch operations",
};

export const USE_PLUGS_CONURRENTLY = {
  pt: "Usar Plugs Simultaneos?",
  es: "Usar Plugs Simultaneos?",
  en: "Use plugs concurrently?",
};

export const SIMULTANEOUS_POWER = {
  pt: "Potência",
};

export const TEXT_CONNECTORS = {
  pt: "Quando há uma carga em andamento, os outros conectores continuam disponíveis.",
};
export const TEXT_POWER = {
  pt: "Quando estão todos conectados, a potência total entregue:",
};

export const PLUGS_CONCURRENTLY = {
  pt: "Conectores funcionam simultaneamente",
  es: "Conectores funcionan simultaneamente",
  en: "Plugs work concurrently",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const POWERS = {
  pt: "Potência",
  es: "Potencia",
  en: "Power",
};

export const SEARCH_BY_NAME = {
  pt: "Pesquise por nome...",
  es: "Busque por nombre...",
  en: "Search by name...",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const STATION_ID = {
  pt: "ID da estação",
  es: "ID de la estación",
  en: "Station ID",
};

export const STATION_ID_EX = {
  pt: "Ex.: CPTUPI001",
  es: "p.ej.: CPTUPI001",
  en: "E.g.: CPTUPI001",
};

export const LOADING = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SEARCH_BY_STATION_ID = {
  pt: "Pesquise pelo ID da estação",
  es: "Busque por el ID de la estación",
  en: "Search by Station ID",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Borrar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const CSV_FILE_NAME = (DATA) => ({
  pt: `Estações conectadas ${DATA}.csv`,
  es: `Estaciones conectadas ${DATA}.csv`,
  en: `Stations connected ${DATA}.csv`,
});

export const DOWNLOAD_CSV = {
  pt: "Baixar em Excel",
  es: "Descargar en Excel",
  en: "Download in Excel",
};

export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const STATE = {
  pt: "Estado",
  es: "Estado",
  en: "State",
};

export const PROVINCE = {
  pt: "Província",
  // TODO: translate es
  // TODO: translate en
};

export const RESERVE_VALUE = {
  pt: "Valor Reserva",
  es: "Valor Reserva",
  en: "Reserve Amount",
};

export const CHARGE_VALUE = {
  pt: "Valor da carga",
  es: "Valor de la carga",
  en: "Value of the charge",
};

export const METHOD_PGT_CHARGE = {
  pt: "Método pgto. carga",
  es: "Método pago carga",
  en: "Charge payment method",
};

export const ENABLED_DATE = {
  pt: "Data da habilitação",
  es: "Fecha de la habilitación",
  en: "Qualification Date",
};

export const STATION_TYPE = {
  pt: "Tipo de estação",
  es: "Tipo de estación",
  en: "Type of station",
};

export const STATION_MODEL = {
  pt: "Marca do carregador",
  es: "Marca del cargador",
  en: "Charger Mark",
};

export const STATION_LOCAL = {
  pt: "Tipo de local",
  es: "Tipo de lugar",
  en: "Type of location.",
};

export const STATION_PARKING_SPOT = {
  pt: "Quanditade de vagas",
  en: "Number of parking spots",
  es: "Capacidad de estacionamiento",
};

export const STATION_PARTNERSHIPS = {
  pt: "Parcerias da estação",
  en: "Partnerships of the station",
  es: "Parceiros de la estación",
};

export const FLAG_TITLE_RECHARGE = {
  pt: "Tipo de recarga",
  en: "Type of recharge",
  es: "Tipo de recarga",
};

export const FLAG_TITLE_ACCESS = {
  pt: "Tipo de acesso",
  en: "Type of access",
  es: "Tipo de acceso",
};

export const FLAG_TITLE_ROAD = {
  pt: "Tipo de via",
  en: "Type of road",
  es: "Tipo de camino",
};

export const EX_PARAM = (value) => ({
  pt: `Ex.: ${value}`,
  en: `Ex.: ${value}`,
  es: `p.ej.: ${value}`,
});

export const FLAG_ROAD = {
  pt: "Rodovia / Estrada",
  en: "road",
  es: "rodovía / carretera",
};

export const FLAG_ROAD_INFO = {
  pt: "Estações / carregadores que estão localizados em rodovias;",
  en: "Stations / chargers located on roads;",
  es: "Estaciones / cargadores que se encuentran en carreteras;",
};

export const FLAG_URBAN = {
  pt: "urbana",
  en: "urban",
  es: "urbana",
};

export const FLAG_URBAN_INFO = {
  pt: "Estações / carregadores que estão localizados em área urbana.",
  en: "Stations / chargers located in urban areas.",
  es: "Estaciones / cargadores que se encuentran en zonas urbanas.",
};

export const FLAG_DESTINATION = {
  pt: "Destination",
  en: "Destination",
  es: "Destination",
};

export const FLAG_DESTINATION_TITLE = {
  pt: ` "Já que estou aqui"`,
  en: `"Already here"`,
  es: `"Ya estoy aqui"`,
};

export const FLAG_DESTINATION_INFO = {
  pt: "Estações e carregadores que estão localizados em supermercados, estacionamentos, restaurantes, hotéis, prédios corporativos, ou seja, o usuário aproveita que está indo utilizar o mercado e utiliza o carregador do estacionamento para fazer a recarga enquanto compra;",
  en: "Stations and chargers located in supermarkets, parking lots, restaurants, hotels, corporate buildings, that is, the user takes advantage of being about to use the market and uses the parking charger to recharge while shopping.",
  es: "Estaciones y cargadores ubicados en supermercados, estacionamientos, restaurantes, hoteles, edificios corporativos, es decir, el usuario aprovecha que está a punto de usar el mercado y utiliza el cargador del estacionamiento para recargar mientras compra.",
};

export const FLAG_ON_THE_GO = {
  pt: "On The Go",
  en: "On The Go",
  es: "On The Go",
};

export const FLAG_ON_THE_GO_TITLE = {
  pt: ` "Preciso recarregar"`,
  en: `"I need to recharge"`,
  es: `"Necesito recargar"`,
};

export const FLAG_ON_THE_GO_INFO = {
  pt: "Postos de combustíveis e estradas. Locais onde o usuário só para com intuito de fazer a recarga, normalmente são carregadores DC de recarga rápida;",
  en: "Gas stations and highways. Places where the user stops solely for the purpose of recharging, typically featuring fast DC chargers.",
  es: "Estaciones de servicio y autopistas. Lugares donde el usuario se detiene únicamente con el propósito de recargar, generalmente con cargadores rápidos de CC.",
};

export const FLAG_HOME_BASED = {
  pt: "Home Based",
  en: "Home Based",
  es: "Home Based",
};

export const FLAG_HOME_BASED_TITLE = {
  pr: ` "Mais prático"`,
  en: `"More practical"`,
  es: `"Más práctico"`,
};

export const FLAG_HOME_BASED_INFO = {
  pt: "Condomínios residenciais, casas, centros de distribuição das empresas (normalmente pontos privados).",
  en: "Residential condominiums, houses, company distribution centers (usually private locations).",
  es: "Condominios residenciales, casas, centros de distribución de empresas (generalmente puntos privados).",
};

export const FLAG_HUB = {
  pt: "Hub",
  en: "Hub",
  es: "Hub",
};

export const FLAG_HUB_INFO = {
  pt: `O mesmo que “On The Go”, mas com uma infraestrutura mais específica e preparada para atender um volume maior de carros. `,
  en: `The same as "On The Go", but with a more specific infrastructure and prepared to handle a larger volume of cars.`,
  es: `Lo mismo que "On The Go", pero con una infraestructura más específica y preparada para atender un volumen mayor de coches.`,
};

export const FLAG_PUBLIC = {
  pt: "público",
  en: "public",
  es: "público",
};

export const FLAG_PUBLIC_INFO = {
  pt: "Qualquer pessoa pode usar;",
  en: "Anyone can use;",
  es: "Cualquiera puede usar;",
};

export const FLAG_SEMI_PUBLIC = {
  pt: "Semipúblico",
  en: "Semi-public",
  es: "Semipúblico",
};

export const FLAG_SEMI_PUBLIC_INFO = {
  pt: "Qualquer pessoa pode usar, desde que use o estabelecimento onde o carregador está inserido (normalmente serão Destination);",
  en: "Anyone can use it, as long as they use the establishment where the charger is located (usually they will be Destination chargers).",
  es: "Cualquier persona puede usarlo, siempre y cuando utilice el establecimiento donde está ubicado el cargador (normalmente serán cargadores de Destino).",
};

export const FLAG_PRIVATE = {
  pt: "privado",
  en: "private",
  es: "privado",
};

export const FLAG_PRIVATE_INFO = {
  pt: "Locais restritos (exemplo: locais que apenas funcionários da empresa parceira podem usar; apenas frotas de determinada empresa...).",
  en: "Restricted locations (example: places that only employees of the partner company can use; only fleets of a certain company...).",
  es: "Lugares restringidos (ejemplo: lugares que solo los empleados de la empresa asociada pueden usar; solo flotas de una empresa específica...).",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const COPIED = {
  pt: "Copiado com sucesso!",
  es: "¡Copiado con éxito!",
  en: "Copied successfully!",
};

export const POWER_PLUG = (powerPlug) => ({
  pt: `Tomada ${powerPlug}`,
  es: `Tomacorriente ${powerPlug}`,
  en: `Outlet ${powerPlug}`,
});

export const PLUG = {
  pt: "Plug",
  es: "Enchufe",
  en: "Plug",
};

export const CURRENT = {
  pt: "Corrente",
  es: "Corriente",
  en: "Current",
};

export const INFORMATION = {
  pt: "INFORMAÇÕES",
  es: "INFORMACIONES",
  en: "INFORMATION",
};

export const STATION_NAME = {
  pt: "Nome da estação",
  es: "Nombre de la estación",
  en: "Station name",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const WITHOUT_COMPANY = {
  pt: "Nenhuma empresa",
  es: "Sin compañía",
  en: "Without company",
};

export const SELECT_COMPANY_WARN = {
  pt: "Só será possível selecionar empresas que estão no país já selecionado",
  // TODO: translate es
  // TODO: translate en
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const STATION_EDIT = {
  pt: "Editar estação",
  es: "Editar estación",
  en: "Edit station",
};

export const STATION_VIEW = {
  pt: "Visualizar estação",
  es: "Para ver estación",
  en: "View station",
};

export const UPKEEP_STATUS = {
  pt: "Ativar status de ”manutenção”",
  es: "Activar estado de ”mantenimiento”",
  en: "Enable ”maintenance” status",
};

export const NO_UPKEEP_STATUS = {
  pt: "Desativar status de ”manutenção”",
  es: "Desactivar estado de ”mantenimiento”",
  en: "Disable ”maintenance” status",
};

export const QUESTION_UPKEEP_STATUS = (upkeepValue) => ({
  pt: `Tem certeza que deseja ${
    upkeepValue ? "DESATIVAR" : "ATIVAR"
  } o status de “manutenção" para esta estação?`,
  // TODO: translate es
  // TODO: translate en
});

export const TOGGLE_UPKEEP_STATUS = (upkeepValue) => ({
  pt: `${upkeepValue ? "Desativar" : "Ativar"} status manutenção`,
  // TODO: translate es
  // TODO: translate en
});

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la empresa",
  en: "Select the company",
};

export const STATION_ACTIVE = {
  pt: "Estação está ativa",
  es: "Estación está activa",
  en: "Station is active",
};

export const STATION_DISABLED = {
  pt: "Estação está desativada",
  es: "Estación está desactivada",
  en: "Station is disabled",
};

export const SELECT_STATE = {
  pt: "Selecione o estado",
  es: "Seleccione el departamento",
  en: "Select State",
};

export const SELECT_PROVINCE = {
  pt: "Selecione a província",
  // TODO: translate es
  // TODO: translate en
};

export const SELECT_CITY = {
  pt: "Selecione a cidade",
  es: "Seleccione la ciudad",
  en: "Select City",
};

export const PRIVATE_STATION = {
  pt: "Estação privada",
  es: "Estación privada",
  en: "Private station",
};

export const PRIVATE_STATION_QUESTION = {
  pt: "Estação privada?",
  es: "¿Estación privada?",
  en: "Private station?",
};

export const OPERATION = {
  pt: "OPERAÇÃO",
  es: "OPERACIÓN",
  en: "OPERATION",
};

export const BUSINESSHOURS = {
  pt: "Horário de funcionamento",
  en: "Business hours",
  es: "Horario de funcionamiento",
};

export const BUSINESSHOURS_INFO = {
  pt: "Selecione os horários e os dias da semana em que a estação funciona.",
  en: "Select the hours and days of the week in which the station works.",
  es: "Seleccione los horarios y los días de la semana en que la estación funciona.",
};

export const DAYS_OF_WEEK = {
  pt: "Dias da semana",
  en: "Days of the week",
  es: "Días de la semana",
};

export const BUSINESS_HOURS_TITLE = {
  pt: "AJUSTES DO HORÁRIO DE FUNCIONAMENTO",
  en: "BUSINESS HOURS SETTINGS",
  es: "AJUSTES DEL HORARIO DE FUNCIONAMIENTO",
};

export const DAYS = {
  pt: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"],
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  es: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
};

export const TWENTY_FOUR_HOURS = {
  pt: "Funcionará 24h",
  en: "Works 24h",
  es: "Trabaja 24h",
};

export const HOUR = {
  pt: "Horário",
  en: "Hour",
  es: "Horario",
};

export const OF = {
  pt: "Das ",
  en: "From ",
  es: "De ",
};

export const TO = {
  pt: " às ",
  en: " to ",
  es: " hasta ",
};
export const TIMEZONE = {
  pt: "Fuso horário",
  en: "Time zone",
  es: "Zona horaria",
};

export const NEW_HOUR = {
  pt: "Horário Adicional:",
  en: "Additional hour:",
  es: "Horario Adicional:",
};

export const BUSINESS_HOURS = {
  pt: "Horário de funcionamento",
  es: "Horario de funcionamiento",
  en: "Opening hours",
};

export const BUSINESS_HOURS_EX = {
  pt: "Ex.: De seg à sex das 8h as 18h",
  es: "p.ej.: De lun a vie de 08h a 18h",
  en: "E.g.: From Mon to Fri from 8am to 6pm",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const FREE_PARKING = {
  pt: "Estacionamento Grátis",
  es: "Estacionamiento Gratuito",
  en: "Free parking",
};

export const FREE_PARKING_QUESTION = {
  pt: "Estacionamento Grátis?",
  es: "¿Estacionamiento Gratis",
  en: "Free parking",
};

export const POWER = (power) => ({
  pt: `Potência${power && " " + power}`,
  es: `Potencia${power && " " + power}`,
  en: `Power${power && " " + power}`,
});

export const SELECT_POWER = {
  pt: "Selecione a potência",
  es: "Seleccione la potencia",
  en: "Select the power",
};

export const ADD_STATION = {
  pt: "Adicione uma estação",
  es: "Agregue una estación",
  en: "Add a station",
};

export const STATION = {
  pt: "Estação",
  es: "Estación",
  en: "Station",
};

export const ADD_POWER_PLUG = {
  pt: "+ Adicionar tomada",
  es: "Agregar tomacorriente",
  en: "+ Add outlet",
};

export const REMOVE_ALL_POWER_PLUGS = {
  pt: "- Remover todas tomadas",
  es: "Retirar todos los tomacorrientes",
  en: "- Remove all outlets",
};

export const COUPONS_PAYMENT = {
  pt: "PAGAMENTO",
};

export const ACCEPT_COUPONS_CHARGE = {
  pt: "Aceita cupom na carga",
  es: "Acepta cupón en la carga",
  en: "Accepts voucher on charge",
};

export const ACCEPT_RESERVE = {
  pt: "Aceita reserva",
  es: "Acepta reserva",
  en: "Accepts reservation",
};

export const PAYMENT_RESERVE = {
  pt: "Pagamento na reserva",
  es: "Pago en la reserva",
  en: "Pay During Reservation",
};

export const RESERVE_VALUE_IN = (currency) => ({
  pt: `Valor da reserva em ${currency}`,
  es: `Valor de la reserva en ${currency}`,
  en: `Reservation amount in ${currency}`,
});

export const TUPI_CHARGER = {
  pt: "Carregador Tupi",
};

export const TUPI_INSTALLER = {
  pt: "Instalação Tupi",
};
export const PARTNER_INSTALLER = {
  pt: "Parceiro que fez a instalação",
};

export const CONTRACT_MODEL_LOWER_CASE = {
  pt: "Modelo de contrato",
};
export const REMUNERATION_ENERGY = {
  pt: "Tem remuneração de energia?",
};

export const REMUNERATION_MEDIA = {
  pt: "Tem remuneração de mídia?",
};

export const INSTALL_DATE = {
  pt: "Data de instalação",
};

export const START_OPERATION = {
  pt: "Início da operação",
};

export const START_CONTRACT = {
  pt: "Início do contrato",
};

export const END_CONTRACT = {
  pt: "Fim do contrato",
};

export const FIRST_PAYMENT = {
  pt: "1ª cobrança",
};

export const VALUE_EX = {
  pt: "Ex.: 5,00",
  es: "p.ej.: 5,00",
  en: "E.g.: 5,00",
};

export const PAYMENT_CHARGE = {
  pt: "Pagamento na carga",
  es: "Pago en la carga",
  en: "Payment on charge",
};

export const OF_KWH = {
  pt: "do kWh",
  es: "de kWh",
  en: "of kWh",
};

export const OTHER_SETTINGS = {
  pt: "OUTRAS CONFIGURAÇÕES",
  es: "OTRAS CONFIGURACIONES",
  en: "OTHER SETTINGS",
};

export const TEST_STATION = {
  pt: "Estação de teste",
  es: "Estación de prueba",
  en: "Test station",
};

export const END_CHARGE_LABEL = {
  pt: "Etiqueta para final de carga",
  es: "Etiqueta para final de carga",
  en: "Label for the end of a charge",
};

export const LABEL = {
  pt: "Etiqueta",
  es: "Etiqueta",
  en: "Label",
};

export const END_CHARGE_LABEL_INFO = {
  pt: 'Será exibida no app ao finalizar uma carga, conforme imagem abaixo. Na ausência de uma etiqueta, o nome "Tupi" será exibido',
  es: 'Se mostrará en la aplicación al final de una carga, como se muestra en la siguiente imagen. Si no hay etiqueta, aparecerá el nombre "Tupi".',
  en: 'It will appear in the app at the end of a charge, as shown below. In the absence of a label, the name "Tupi" will be displayed',
};

export const BRAND_EX = {
  pt: "Ex.: Tupi",
  es: "p.ej.: Tupi",
  en: "E.g.: Tupi",
};

export const QUALIFICATION_DATE = {
  pt: "Data da habilitação",
  es: "Fecha de la habilitación",
  en: "Qualification Date",
};

export const SELECT_DATE_TIME = {
  pt: "Selecione a data/hora",
  es: "Seleccione la fecha/hora",
  en: "Select Date/Time",
};

export const VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "Value",
};

export const LATITUDE = {
  pt: "Latitude",
  es: "Latitud",
  en: "Latitude",
};

export const LONGITUDE = {
  pt: "Longitude",
  es: "Longitud",
  en: "Longitude",
};

export const LATITUDE_EX = {
  pt: "Ex.: -00.0000",
  es: "p.ej.: -00.0000",
  en: "Example: -00.0000",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa",
  en: "Ops",
};

export const ERROR_UPKEEP = {
  pt: "Erro ao ativar o status de manutenção",
  // TODO: translate es
  // TODO: translate en
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const LISTING = {
  pt: "Listagem",
  es: "Listado",
  en: "Listing",
};

export const STATION_AUDITED = {
  pt: "Estação auditada",
  es: "Estación auditada",
  en: "Audited station",
};

export const AUDIT_DELETED = {
  pt: "Auditoria apagada.",
  es: "Auditoría borrada.",
  en: "Deleted audit.",
};

export const OPEN_IN_GOOGLE_MAPS = {
  pt: "Abrir no Google Maps",
  es: "Abrir en Google Maps",
  en: "Open on Google Maps",
};

export const AUDITS = {
  pt: "Auditoria",
  es: "Auditoría",
  en: "Audit",
};

export const AUDITED = {
  pt: "Auditado",
  es: "Auditado",
  en: "Audited",
};

export const UPDATE = {
  pt: "Atualizar",
  es: "Actualizar",
  en: "Update",
};

export const AUDIT_STATION = {
  pt: "Auditar Estação",
  es: "Auditar Estación",
  en: "Audit Station",
};

export const SELECT_PLUGS = {
  pt: "Selecione os tipos de plugs disponíveis",
  es: "Seleccione los tipos de tapones disponibles",
  en: "Select the available plugs",
};

export const SELECT_LOCALIZATION = {
  pt: "Selecione um local",
  es: "Selecciona una localización",
  en: "Select a localization",
};

export const LOCALIZATION = {
  pt: "Local",
  es: "Localización",
  en: "Localization",
};

export const DISCART_DELETE = {
  pt: "Descartar (apagar)",
  es: "Descartar (borrar)",
  en: "Discard (delete)",
};

export const CONFIRM_DISCART = {
  pt: "Deseja realmente DESCARTAR?",
  es: "¿Desea realmente DESCARTAR?",
  en: "Do you really want to DISCARD?",
};

export const APPROVE = {
  pt: "Aprovar",
  es: "Aprobar",
  en: "Approve",
};

export const CONFIRM_APPROVE = {
  pt: "Deseja realmente APROVAR?",
  es: "¿Realmente desea APROBAR?",
  en: "Do you really want to APPROVE?",
};

export const MONTH_TIME = {
  pt: "L HH[h]mm",
};

export const METHOD = {
  pt: "Modelo de cobrança",
};

export const METHOD_CHARGE = {
  pt: "Cobrança",
};

export const METHOD_KWH = {
  pt: "Por kWh",
};

export const METHOD_TIME = {
  pt: "Por tempo",
};

export const METHOD_FIXED = {
  pt: "Valor fixo",
};

export const METHOD_FIXED_DESCRIPTION = (value, perTime, timeWindow) => ({
  pt: `${value} fixo a cada ${perTime} ${timeWindow}`,
});

export const METHOD_RELATIVE_DESCRIPTION = (value, perTime, timeWindow) => ({
  pt: `${value} a cada ${perTime} ${timeWindow}`,
});

export const METHOD_DESCRIPTION = {
  pt: "a cada",
};

export const METHOD_TIME_RELATIVE = {
  pt: "Proporcional por período de tempo",
};

export const METHOD_TIME_FIXED = {
  pt: "Fixa por período de tempo",
};

export const METHOD_TIME_HOUR = {
  pt: "Hora(s)",
};

export const METHOD_TIME_MIN = {
  pt: "Minuto(s)",
};

export const PAYMENT_CHARGE_RESUME = {
  pt: "Resumo da cobrança",
};

export const PAYMENT_DESCRIPTION_INCOMPLETE = {
  pt: "Complete as informações",
};

export const PAYMENT_PERIOD = {
  pt: "Período de tempo",
};

export const TYPE_OF_VISIBILITY = {
  pt: "Selecione o tipo de visibilidade",
};

export const CREATE_NEW_ACCOUNT = {
  pt: "Criar nova conta",
};

export const DISABLE = {
  pt: "Desativar",
  es: "Desactivar",
  en: "Disable",
};

export const LOADING_CSV = {
  pt: "Carregando",
  es: "Cargando",
  en: "Loading",
};

export const SELECT_VISIBILITY_TYPE = {
  pt: "Selecione o tipo de visibilidade",
};

export const CONTRACT_MODEL = {
  pt: "MODELO E CONTRATO",
};

export const PARTNER_NAME = {
  pt: "Nome do parceiro",
};

export const SELECT = {
  pt: "Selecione",
};

export const NO_INFO = {
  pt: "Sem info",
};

export const CAAS_CONTRACT_MODEL = {
  en: "CaaS (Charger as a service)",
};

export const EAAS_CONTRACT_MODEL = {
  en: "EaaS (Energy as a service)",
};

export const SAAS_CONTRACT_MODEL = {
  en: "SaaS (Software as a service)",
};

export const RECIPIENT = {
  pt: "Recebedor",
  es: "Receptor",
  en: "Recipient",
};

export const PERCENTAGE = {
  pt: "Porcentagem",
};

export const PAYMENT_SPLIT_INSTRUCTION = {
  pt: "A divisão de pagamento é configurada no menu Empresas.",
};

export const EDIT_INFO = {
  pt: "Editar informações",
};
export const TOTAL = {
  pt: "Total",
  es: "Totale",
  en: "Total",
};

export const ADD_RECIPITER = {
  pt: "Adicionar recebedor",
};

export const ERROR_GET_STATES = {
  pt: "Erro ao buscar a lista de estados",
  // TODO: translate es
  // TODO: translate en
};

export const ERROR_GET_CITIES = {
  pt: "Erro ao buscar a lista de cidades",
  // TODO: translate es
  // TODO: translate en
};

export const SELECT_COUNTRY = {
  pt: "País",
  // TODO: translate es
  // TODO: translate en
};

export const SELECT_COUNTRY_ESTATIONS = {
  pt: "País das estações",
  es: "País de las estaciones",
  en: "Country of the stations",
};

export const NO_INFORMATIONS = {
  pt: "Sem informações",
  es: "Sin información",
  en: "No information",
};

export const IS_DIVIDE_POWER = {
  pt: "É dividida entre os conectores",
  en: "It is divided between the connectors",
  es: "Se divide entre los conectores",
};

export const FIXED_POWER_BETWEEN_PLUGS = {
  pt: "Continua fixa nos conectores",
  en: "Remains fixed in the connectors",
  es: "Permanece fija en los conectores",
};

export const ATTENTION = {
  pt: "Atenção",
};

export const CAMPAIGN_RULES = {
  pt: "REGRAS DA CAMPANHA",
  // TODO: translate en
  // TODO: translate es
};

export const COD_ERROR_LETTER = {
  pt: "* Apenas letras (A-Z) e números (0-9).",
  en: "* Only letters (A-Z) and numbers (0-9).",
  es: "* Sólo letras (A-Z) y números (0-9)",
};

export const COD_ERROR_MIN = {
  pt: "* Deve ser pelo menos 4 caracteres",
  en: "* Must be at least 4 characters",
  es: "* Debe tener al menos 4 caracteres",
};

export const ENTER_PREFIX = {
  pt: "Digite o prefixo:",
  // TODO: translate en
  // TODO: translate es
};

export const QTD_COUPONS = {
  pt: "Quantidade de cupons",
  en: "Coupons quantity",
  es: "Número de cupones",
};

export const QUESTION_PREFIX_DESCRIPTION = {
  pt: "Serão adicionados ao prefixo 6 números e letras complementares",
  // TODO: translate en
  // TODO: translate es
};

export const VALUE_CREDIT = (currency) => ({
  pt: `Valor em crédito (${currency})`,
  // TODO: translate en
  // TODO: translate es
});

export const ALL_COMPANIES_AND_STATIONS = {
  pt: "Todas as empresas e estações",
  // TODO: translate en
  // TODO: translate es
};

export const ALL_NETWORK_COMPANIES_AND_STATIONS = {
  pt: "Todas as empresas e estações da rede",
  // TODO: translate en
  // TODO: translate es
};

export const ALL_STATIONS = {
  pt: "Todas as estações",
  // TODO: translate en
  // TODO: translate es
};

export const SPECIFIC_COMPANIES_AND_STATIONS = {
  pt: "Selecionar estações de empresas específicas",
  // TODO: translate en
  // TODO: translate es
};

export const SPECIFIC_STATIONS = {
  pt: "Selecionar estações específicas",
  // TODO: translate en
  // TODO: translate es
};

export const VALID_PLACES = {
  pt: "LOCAIS VÁLIDOS",
  // TODO: translate en
  // TODO: translate es
};

export const VALID_PLACES_DESCRIPTION = {
  pt: "Os cupons serão válidos nas suas empresas e estações.",
  // TODO: translate en
  // TODO: translate es
};

export const WARNING_BUSINESS_HOUR = {
  pt: `Clique em “Salvar horário” para confirmar as alterações feitas no horário de funcionamento.`,
  en: `Click on “Save Hour” to confirm the changes made in the business hour.`,
  es: `Haga clic en “Guardar horário” para confirmar los cambios realizados en el horario de funcionamiento.`,
};

export const WARNING_BUSINESS_HOUR_NOT_SAVED = {
  pt: "As alterações feitas no horário de funcionamento não foram salvas.",
  en: "The changes made in the business hour were not saved.",
  es: "Los cambios realizados en el horario de funcionamiento no fueron guardados.",
};

export const BATCH_OPERATION = {
  pt: "Operação em lote",
  es: "Operación por lote",
  en: "Batch operation",
};

export const SELECT_STATIONS = {
  pt: "SELECIONAR ESTAÇÕES",
  es: "SELECCIONAR ESTACIONES",
  en: "SELECT STATIONS",
};

export const UPDATE_BATCH_PRICES = {
  pt: "Atualizar preços em lote",
  es: "Actualizar precios en lote",
  en: "Update prices in batch",
};

export const UPDATE_BATCH_PRICES_DESCRIPTION = {
  pt: "Selecione as estações e defina um novo preço",
  es: "Seleccione las estaciones y defina un nuevo precio",
  en: "Select the stations and set a new price",
};

export const UPDATE_PRICES = {
  pt: "ATUALIZAR PREÇOS",
};

export const STATION_CODE = {
  pt: "Código da estação",
  en: "Station code",
  es: "Código de la estación",
};

export const UPDATE_CHARGE_METHOD = {
  pt: "ATUALIZAR COBRANÇA",
  en: "UPDATE CHARGE",
  es: "ACTUALIZAR COBRANZA",
};

export const UPDATE_CHARGE_METHOD_DESCRIPTION = {
  pt: "Modifique as configurações desejadas",
  en: "Modify the desired configurations",
  es: "Modifique las configuraciones deseadas",
};

export const SHOW_IDLE_FEE = {
  pt: "Taxa de ociosidade",
  en: "Idle fee",
  es: "Tarifa de ociosidad",
};

export const SHOW_SELECT_PAYMENT = {
  pt: "Forma de pagamento",
  en: "Payment method",
  es: "Metodo de pago",
};

export const IDLE_FEE = {
  pt: "Taxa de ociosidade",
  en: "Idle fee",
  es: "Tarifa de ociosidad",
};

export const ACCEPT_IDLE_FEE = {
  pt: "Aceita taxa de ociosidade",
  en: "Accepts idle fee",
  es: "Acepta tarifa de ociosidad",
};

export const BUTTON_KEEP = {
  pt: "Manter atual",
  es: "Mantener actual",
  en: "Keep current",
};

export const BUTTON_CHANGE = {
  pt: "Modificar",
  es: "Cambiar",
  en: "Change",
};

export const VALUE_PER_MINUTE = {
  pt: "Valor por minuto",
  en: "Value per minute",
  es: "Valor por minuto",
};

export const GRACE_PERIOD = {
  pt: "Tempo de carência (minuto)",
  en: "Grace period (minute)",
  es: "Período de gracia (minuto)",
};

export const GRACE_PERIOD_MINUTES = {
  pt: "Em Minutos",
  en: "In minutes",
  es: "En minutos",
};

export const ERROR_GET_LOCATIONS = {
  pt: "Erro ao buscar locais",
  en: "Error fetching locations",
  es: "Error al buscar lugares",
};

export const ERROR_LOCATION_WITHOUT_COMPANY_IN_CONNECTED_STATION = {
  pt: "É necessário selecionar um local com uma empresa para estação conectada.",
  en: "It is necessary to select a location with a company for connected station.",
  es: "Es necesario seleccionar un lugar con una empresa para la estación conectada.",
};

export const NAME_STATION = {
  pt: "Nome da estação",
  en: "Station name",
  es: "Nombre de la estación",
};

export const IDLE_WARNING = {
  pt: "Estações que não tiverem cobrança na carga não poderão ter a taxa de ociosidade habilitada.",
  es: "Las estaciones que no tienen carga de carga no pueden tener habilitada la tarifa de inactividad",
  en: "Stations that do not have load charging cannot have the idle fee enabled",
};

export const SHARE = {
  pt: "Compartilhar",
  es: "Compartir",
  en: "Share",
};

export const SHARE_STATION_TITLE = {
  pt: "Compartilhar estação",
  es: "Compartir estación",
  en: "Share station",
};

export const COPY_SHARE_LINK = {
  pt: "Copiar link de compartilhamento",
  es: "Copiar enlace de compartir",
  en: "Copy sharing link",
};

export const SHARE_LINK_COPIED = {
  pt: "Link de compartilhamento copiado",
  es: "Enlace de compartir copiado",
  en: "Sharing link copied",
};

export const CONNECTED_STATION_WARN = {
  pt: "Estação conectada deve ter uma empresa associada.",
  es: "La estación conectada debe tener una empresa asociada.",
  en: "Connected station must have a company associated.",
};

export const ERROR_PRICE_PER_HOUR_OVERLAPPING_RANGES = {
  pt: "Não é possível definir mais de um preço para um mesmo horário.",
  en: "It is not possible to define more than one price for the same hour.",
  es: "No es posible fijar más de un precio al mismo tiempo.",
};

export const NEW_STATION = {
  pt: "Nova estação",
  es: "Nueva estación",
  en: "New station",
};
