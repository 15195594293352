export const STATION_SELETION = {
  pt: "RESUMO DA SELEÇÃO DE ESTAÇÕES",
  es: "RESUMO DE LA SELECCIÓN DE LA ESTACIÓN",
  en: "STATION SELETION SUMMARY",
};

export const ID = {
  pt: "ID",
  es: "ID",
  en: "ID",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const CURRENT_CHARG_PAYMENT = {
  pt: "Pagamento atual na carga",
  es: "Pago actual en la carga",
  en: "Current charge payment",
};

export const CURRENT_IDLE_FEE = {
  pt: "Taxa de ociosidade atual",
  es: "Tarifa de ociosidad actual",
  en: "Current idle fee",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const UPDATE = {
  pt: "Atualizar",
  es: "Actualizar",
  en: "Update",
};

export const YES_UPDATE = {
  pt: "Sim, atualizar",
  es: "Sí, actualizar",
  en: "Yes, update",
};

export const CONFIRM_UPDATE = {
  pt: `Você tem certeza que deseja alterar todas as estações selecionadas? Clique novamente para confirmar`,
  es: `¿Está seguro de que desea cambiar todas las estaciones seleccionadas? Haga clic nuevamente para confirmar`,
  en: `Are you sure you want to change all selected stations? Click again to confirm`,
};

export const NO_CHANGES = {
  pt: "Nenhuma alteração foi feita.",
  es: "Error al buscar las estaciones",
  en: "Error searching stations",
};

export const ERROR_GET_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error al buscar las estaciones",
  en: "Error searching stations",
};

export const WENT_WRONG = {
  pt: "Algo deu errado!",
  es: "¡Algo ha salido mal!",
  en: "Something went wrong!",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡De acuerdo!",
  en: "All right!",
};

export const STATION_EDITED = {
  pt: "Estações editadas com sucesso!",
  es: "¡Estaciones editadas con exito!",
  en: "Stations successfully edited!",
};

export const UPDATE_CONFIRM = {
  pt: "Tem certeza que deseja atualizar as estações selecionadas com as configurações de preço acima?",
  es: "¿Seguro que desea actualizar las estaciones seleccionadas con las configuraciones de precio acima?",
  en: "Are you sure you want to update the selected stations with the above price settings?",
};

export const PAYMENT_DISABLE_IDLE_FEE = {
  pt: `Ao desabilitar o pagamento na carga das estações selecionadas você estará desabilitando automaticamente a cobrança de taxa de ociosidade e taxa de ativação, caso existam.`,
  es: `Al deshabilitar el pago en la carga de las estaciones seleccionadas, deshabilitará automáticamente el cobro de la tasa de inactividad y la tasa de activación, si hay alguna.`,
  en: `By disabling payment on the selected stations' load, you will automatically disable idle fee and activation charging, if there are any.`,
};

export const NO_PRICE = {
  pt: "Sem pagamento na carga",
  es: "Sin pago en la carga",
  en: "No charge payment",
};

export const NO_ACCEPT_PAYMENT = {
  pt: "Não aceita pagamento na carga",
  es: "No acepta pago en la carga",
  en: "Don't accepts charge payment",
};

export const NO_IDLE_FEE = {
  pt: "Sem taxa de ociosidade",
  es: "Sin tarifa de ociosidad",
  en: "No idle fee",
};

export const NO_ACCEPT_IDLE_FEE = {
  pt: "Não aceita taxa de ociosidade",
  es: "No acepta tarifa de ociosidad",
  en: "Don't accepts idle fee",
};

export const NO_ALTERATIONS = {
  pt: "Sem alterações",
  es: "Sin cambios",
  en: "No changes",
};

export const IDLE_FEE = {
  pt: "Taxa de ociosidade",
  es: "Tarifa de ociosidad",
  en: "Idle fee",
};

export const VALUE_PER_MIN = {
  pt: "Valor por minuto:",
  es: "Valor por minuto:",
  en: "Value per minute:",
};

export const GRACE_PERIOD = {
  pt: "Tempo de carência:",
  es: "Período de gracia:",
  en: "Grace period:",
};

export const ACCEPT_COUPON = {
  pt: "Aceita cupom na carga",
  es: "Acepta cupón en la carga",
  en: "Accepts charge coupon",
};

export const NO_ACCEPT_COUPON = {
  pt: "Não aceita cupom na carga",
  es: "No acepta cupón en la carga",
  en: "Don't accepts charge coupon",
};

export const UPDATED_PRICE = {
  pt: "ATUALIZAÇÕES",
  es: "ACTUALIZACIONES",
  en: "UPDATES",
};

export const PAYMENT_CHARGE = {
  pt: "Pagamento na carga",
  es: "Pago en la carga",
  en: "Charge payment",
};

export const COUPON_CHARGE = {
  pt: "Cupom na carga",
  es: "Cupon en la carga",
  en: "Charge coupon",
};

export const CHARGE_MODEL = {
  pt: "Modelo de cobrança:",
  es: "Modelo de cobrança:",
  en: "Charge model:",
};

export const VALUE = {
  pt: "Valor:",
  es: "Valor:",
  en: "Value:",
};

export const ERROR_INFO_CHARGE_PAYMENT_METHOD = {
  pt: "Informe o método do pagamento da carga",
  es: "Informe el método del pago de la carga",
  en: "Specify the method of payment of the charge",
};

export const ERROR_INFO_CHARGE_PAYMENT = {
  pt: "Informe o valor do pagamento do método da carga",
  es: "Informe el valor del pago del método de la carga",
  en: "Specify the payment amount of the charge method",
};

export const ERROR_INFO_IDLE_FEE = {
  pt: "Informe o valor da taxa de ociosidade",
  es: "Informe el valor de la tarifa de ociosidad",
  en: "Enter the idle fee",
};

export const ERROR_INFO_IDLE_FEE_GRACE_PERIOD = {
  pt: "Informe o tempo de carência da taxa de ociosidade",
  es: "Informe el tiempo de gracia de la tarifa de ociosidad",
  en: "Enter the grace period of the idle fee",
};

export const WARNING_NO_STATIONS_TO_BE_UPDATED = {
  pt: "Nenhuma estação selecionada pode ser atualizada, volte e revise as informações.",
  es: "No se pueden actualizar estaciones seleccionadas, regrese y revise la información.",
  en: "No selected stations can be updated, please go back and review the information.",
};

export const SHOW_MORE = {
  pt: "Ver mais",
  es: "Ver más",
  en: "Show more",
};

export const HAS_CUSTOM_PRICE_PER_HOUR = {
  pt: "Possui preço customizado por horário",
  es: "Tiene un precio personalizado por horario",
  en: "Has a custom price per hour",
};

export const OF = {
  pt: "Das",
  es: "De",
  en: "Of",
};

export const TO = {
  pt: "As",
  es: "A",
  en: "To",
};

export const PRICE_PER_HOUR = {
  pt: "Preço por horário",
  es: "Precio por horario",
  en: "Price per hour",
};
